<template>
  <header class="login-header">
    <div class="container">
      <h1 class="logo">
        <RouterLink to="/">
					  <img src="http://file.qltrade.ixiaoguo.com.cn/file/4255933719577558.png" />
				</RouterLink>
      </h1>
      <h3 class="sub">
        <slot></slot>
      </h3>
      <RouterLink class="entry" to="/">
        <img src="../../../../static/icons/iphone.png" alt="" />
        <span style="color: #d12b23; font-weight: 800">
          热线电话: 400-8889-65881
        </span>
      </RouterLink>
    </div>
  </header>
</template>

<script>
export default {
  name: 'LoginHeader',
}
</script>

<style scoped lang="less">
.login-header {
  background: #fff;
  border-bottom: 1px solid #e4e4e4;
  .container {
    width: 70%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .logo {
    width: 200px;
		img {
      display: block;
      height: 110px;
      width: 100%;
      text-indent: -9999px;
			padding: 10px;
    }
    // a {
    //   display: block;
    //   height: 132px;
    //   width: 100%;
    //   text-indent: -9999px;
    //   background: url(../../../../static/icons/log.png) no-repeat center 18px /
    //     contain;
    // }
  }
  .sub {
    flex: 1;
    font-size: 20px;
    font-weight: normal;
     margin-bottom: 38px;
    margin-left: 20px;
    color: #666;
  }
  .entry {
    width: 250px;
    margin-bottom: 38px;
    font-size: 16px;
    i {
      font-size: 14px;
      color: @xtxColor;
      letter-spacing: -5px;
    }
  }
}
// .container-iphone {
//   height: 100%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   color: #e4e4e4;
// }
</style>
