<template>
  <form class="xtx-form" @submit="onSubmitHandler">
    <div class="xtx-form-item">
      <div class="field">
        <i class="icon iconfont icon-user"></i>
        <input
          v-model="accountField"
          class="input"
          type="text"
          placeholder="请输入用户名"
        />
      </div>
      <div v-if="accountError" class="error">{{ accountError }}</div>
    </div>
    <div class="xtx-form-item">
      <div class="field">
        <i class="icon iconfont icon-phone"></i>
        <input
          v-model="mobileField"
          class="input"
          type="text"
          placeholder="请输入手机号"
        />
      </div>
      <div v-if="mobileError" class="error">{{ mobileError }}</div>
    </div>
    <div class="xtx-form-item">
      <div class="field">
        <i class="icon iconfont icon-code"></i>
        <input
          v-model="codeField"
          class="input"
          type="text"
          placeholder="请输入验证码"
        />
        <span class="code" @click="getMsgCode">{{
          isActive ? `剩余${count}秒` : "发送验证码"
        }}</span>
      </div>
      <div v-if="codeError" class="error">{{ codeError }}</div>
    </div>
    <div class="xtx-form-item">
      <div class="field">
        <i class="icon iconfont icon-lock"></i>
        <input
          v-model="passwordField"
          class="input"
          type="password"
          placeholder="请输入密码"
        />
      </div>
      <div v-if="passwordError" class="error">{{ passwordError }}</div>
    </div>
    <div class="xtx-form-item">
      <div class="field">
        <i class="icon iconfont icon-lock"></i>
        <input
          v-model="rePasswordField"
          class="input"
          type="password"
          placeholder="请确认密码"
        />
      </div>
      <div v-if="rePasswordError" class="error">{{ rePasswordError }}</div>
    </div>
    <input type="submit" class="submit" value="立即绑定" />
  </form>
</template>

<script>
import useBindNewAccountFormValid from '@/hooks/login/useBindNewAccountFormValid'

export default {
  name: 'LoginCallbackBindPatch',
  props: {
    unionId: {
      type: String,
      default: '',
    },
  },
  setup (props) {
    const { onSubmitHandler, ...bindNewAccountValid } =
      useBindNewAccountFormValid(props)
    return { onSubmitHandler, ...bindNewAccountValid }
  },
}
</script>

<style scoped lang="less">
.code {
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
  width: 80px;
  color: #999;
  &:hover {
    cursor: pointer;
  }
}
</style>
